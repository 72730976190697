<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="green" indeterminate></v-progress-circular>
    </v-overlay>
    <v-container>
      <!-- <v-col class="card-shadow border-radius-xl mt-6">

        <v-row class="m-0">
          <v-col lg="4" md="4" cols="12">
            <v-form ref="form">
              <input type="text" class="codescan_input" v-model="scanned_batch_no" @input="newScan()">
            </v-form>
          </v-col>

        </v-row>

      </v-col> -->

      <v-banner single-line :sticky="true" class="card-shadow border-radius-xl mt-6" style="background:#fff;">
        <v-row class="m-0">
          <v-col lg="4" md="4" cols="12">
            <v-form ref="form">
              <input type="text" class="codescan_input" v-model="scanned_batch_no" ref="scanned_batch_no"
                @input="newScan()">
            </v-form>
          </v-col>

        </v-row>

      </v-banner>

      <v-card class="card-shadow cstmcard-bg1 border-radius-xl mt-6">
        <v-row class="m-0">
          <v-col v-for="item in data" :key="item.batch_no" class="cstm_card1">
            <v-card class="pb-3">


              <v-list-item :style="{ 'padding': '0px', 'border-radius': '10px', 'background': itemStatus(item).color }">
                <v-subheader style="padding:0px 10px; color: #000;"> Batch : </v-subheader>
                <v-list-item-content><b style="font-size: 14px; margin-top: 2px;">{{
                  item.batch_no
                }}</b></v-list-item-content>
              </v-list-item>


              <v-col class="cardmark-mb" style="padding:0px;">
                <v-list-item style="padding:0px;">
                  <v-subheader> Mark : </v-subheader>
                  <v-list-item-content><b style="color: rgba(0, 0, 0, 0.6);font-size: 14px; margin-top: 2px;">{{
                    item.mark_name
                  }}</b></v-list-item-content>
                </v-list-item>
              </v-col>

              <v-row class="m-0">

                <v-col cols="5" class="card-padadjust">
                  <v-list-item style="padding:0px;">
                    <v-subheader> Grade : </v-subheader>
                    <v-list-item-content> <b
                        style="color: rgba(0, 0, 0, 0.6); font-size: 14px;
                                                                                                        margin-top: 2px;">{{
                                                                                                          item.grade_name
                                                                                                        }}</b></v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="7" class="card-padadjust">
                  <v-list-item style="padding:0px;">
                    <v-subheader> INV No : </v-subheader>
                    <v-list-item-content><b
                        style="color: rgba(0, 0, 0, 0.6); font-size: 14px;
                                                                                                        margin-top: 2px;">{{
                                                                                                          item.invoice_no
                                                                                                        }}</b></v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="5" class="card-padadjust">
                  <v-list-item style="padding:0px;">
                    <v-subheader> Qty : </v-subheader>
                    <v-list-item-content><b style="color: rgba(0, 0, 0, 0.6); font-size: 14px; margin-top: 2px;">{{
                      item.qty
                    }}</b></v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="7" class="card-padadjust">
                  <v-list-item style="padding:0px;">
                    <v-subheader> Net Wt : </v-subheader>
                    <v-list-item-content><b
                        style="color: rgba(0, 0, 0, 0.6); font-size: 14px;
                                                                                                        margin-top: 2px;">{{
                                                                                                          item.net_kg + "kg"
                                                                                                        }}</b></v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="5" class="card-padadjust">
                  <v-list-item style="padding:0px;">
                    <v-subheader> Bags : </v-subheader>
                    <v-list-item-content><b
                        style="color: rgba(0, 0, 0, 0.6); font-size: 14px;
                                                                                                        margin-top: 2px;">{{
                                                                                                          item.bags
                                                                                                        }}</b></v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="7" class="card-padadjust">
                  <v-list-item style="padding:0px;">
                    <v-subheader> Remaining : </v-subheader>
                    <v-list-item-content v-if="item.remaining_bags > 0"><b
                        style="color: rgba(0, 0, 0, 0.6); font-size: 14px; margin-top: 2px;">{{
                          item.remaining_bags
                        }}</b></v-list-item-content>
                    <v-list-item-content v-else>0</v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>

            </v-card>

          </v-col>
        </v-row>
      </v-card>
      <v-col cols="12" md="4" align-self="center" class="mt-5">
        <v-btn color="primary" elevation="0" :ripple="false" height="43" dark
          class="text-capitalize btn-ls btn-primary bg-success py-3 px-6" @click="saveDraft()">
          Save Draft
        </v-btn>
        <v-btn color="primary" elevation="0" :ripple="false" height="43" dark
          class="text-capitalize btn-ls btn-primary bg-success py-3 px-6 ml-2" @click="completed()">
          Completed
        </v-btn>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import api from "../api";

export default {
  // name: "ITDI Details",
  computed: {},
  data() {
    return {
      overlay: false,
      data: [],
      childData: {
        no_bags: 0,
        status: '',
        dispatch_child_id: 0,
      },
      updateChildData: {
        id: 0,
        no_bags: 0,
        status: '',
        dispatch_child_id: 0,
      },
      itdiMasterData: {
        dispatch_master_id: 0,
        status: '',
        itdiChild: []
      },
      id: this.$route.params.id,
      currentPage: this.$route.query.currentPage,
      scanned_batch_no: null
    };
  },
  methods: {
    async onInit() {
      this.overlay = true;
      this.data = await api.getById(this.id);
      this.$refs.scanned_batch_no.focus();
      console.log(this.data);
      this.overlay = false;
    },
    newScan() {
      let batch_found = false;
      this.data.forEach((el) => {
        if (this.scanned_batch_no == el.batch_no) {
          el.status = 'DRAFT';
          el.remaining_bags -= 1;
          if (el.remaining_bags >= 0) {
            this.showSuccessAlert("Remaining bag count: " + el.remaining_bags);

            this.scanned_batch_no = null;
            if (el.remaining_bags === 0) {
              el.status = 'COMPLETED';
            }
          } else {
            this.showErrorAlert("Scanned count exceeds recorded bag count");
            this.scanned_batch_no = null;
            el.status = 'EXCESS';
          }
          console.log("Scanned bags: " + (el.bags - el.remaining_bags));
          batch_found = true;
        }
      });
      if (this.scanned_batch_no.length != 0 && !batch_found) {
        this.scanned_batch_no = null;
        this.showErrorAlert("Batch not found");
      }
      console.log(this.scanned_batch_no);
      this.$refs.scanned_batch_no.focus();
    },
    async saveDraft() {
      await this.save('DRAFT');
    },
    async completed() {
      await this.save('COMPLETED');
    },
    async save(status) {
      this.itdiMasterData = {
        dispatch_master_id: 0,
        status: '',
        itdiChild: []
      };
      this.itdiMasterData.status = status;

      if (this.data[0].itdim_id) {  //update
        this.data.forEach(async (el) => {
          this.initChildData();
          this.updateChildData.id = el.itdi_id;
          this.updateChildData.no_bags = el.bags - el.remaining_bags;
          if (status === 'COMPLETED' && el.status !== 'EXCESS') {
            this.updateChildData.status = 'COMPLETED';
          } else {
            this.updateChildData.status = el.status;
          }
          this.updateChildData.dispatch_child_id = el.dispatch_child_id;

          this.itdiMasterData.itdiChild.push(this.updateChildData);
          this.itdiMasterData.dispatch_master_id = el.dispatch_master_id;
        });
        console.log(this.itdiMasterData);
        // console.log(this.data[0].itdim_id);
        // console.log(this.itdiMasterData.itdiChild);

        console.log("update");
        await api.update(this.data[0].itdim_id, this.itdiMasterData)
          .then(async () => {
            this.showSuccessAlert("Data updated successfully");
            this.goToPreviousPage();
          })
          .catch((err) => {
            console.log(err.response.data.message);
            throw new Error(err.response.data.message);
          });
      } else {                          //create
        this.data.forEach(async (el) => {
          this.initChildData();
          this.childData.no_bags = el.bags - el.remaining_bags;
          if (status === 'COMPLETED' && el.status !== 'EXCESS') {
            this.childData.status = 'COMPLETED';
          } else {
            this.childData.status = el.status;
          }
          this.childData.dispatch_child_id = el.dispatch_child_id;

          this.itdiMasterData.itdiChild.push(this.childData);
          this.itdiMasterData.dispatch_master_id = el.dispatch_master_id;
        });
        console.log(this.itdiMasterData);
        // console.log(this.data[0].itdim_id);
        // console.log(this.itdiMasterData.itdiChild);

        console.log("save");
        await api.create(this.itdiMasterData)
          .then(() => {
            this.showSuccessAlert("Data saved successfully");
            this.goToPreviousPage();
          })
          .catch((err) => {
            console.log(err.response.data.message);
            throw new Error(err.response.data.message);
          });
      }
    },
    initChildData() {
      this.childData = {
        no_bags: 0,
        status: '',
        dispatch_child_id: 0,
      };
      this.updateChildData = {
        id: 0,
        no_bags: 0,
        status: '',
        dispatch_child_id: 0,
      };
    },
    itemStatus(item) {
      if (item.status === 'EXCESS') {
        return { color: "#ffb324" }; //blue
      } else if (item.status === 'COMPLETED') {
        return { color: "#ff6500" }; //green
      } else if (item.status === 'DRAFT') {
        return { color: "#38bd34" }; //orange
      } else {
        return { color: "#d7ffd6" }; //red
      };
    },
    async goToPreviousPage() {
      this.overlay = true;
      this.$router.push({
        name: "Internal Transfer Dispatch Inspection",
        query: {
          currentPage: this.currentPage,
        },
      });
      this.overlay = false;
    },
    showSuccessAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        type: "success",
        timer: 2000,
        icon: "success",
      });
    },
    showErrorAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        type: "error",
        icon: "error",
        timer: 2000,
      });

    },

  },
  async mounted() {
    await this.onInit();
  },
};
</script>

<style>
.codescan_input {
  border: 2px solid #cccccc !important;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 10px;
}

.cstm_card1 .v-subheader {
  padding: 0px 7px 0px 5px;
}

.cstm_card1 {
  border-radius: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 15px -7px #6e6e6e;
  border: 1px solid #d6d6d6;
}

.cstmcard-bg1 {
  padding: 30px 15px 15px 30px;
}

.card-padadjust {
  padding: 0px 10px;

}

.cardmark-mb {
  margin-bottom: 7px;
}


.card-padadjust v-subheader {
  height: 30px !important;
}

.clr-dots {
  width: 15px;
  height: 15px;
  background: green;
}


.theme--light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper {
  border: none !important;
}


@media screen and (max-width: 575px) {

  .cstmcard-bg1 {
    padding: 20px;
  }

  .cstm_card1 {
    margin-right: 0px;
  }

  .cstm_card1 b {
    font-size: 11px !important;
  }

  .cstm_card1 .v-subheader {
    font-size: 11px !important;
    height: 30px;
  }

}
</style>
